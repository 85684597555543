// trend book pdf viewer
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core'
import { customAnnotationPlugin } from '../../../../Plugins/customAnnotationPlugin'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail'

import AdditionalContent from './Components/AdditionalContent'
import Downloads from './Components/Downloads'
import ExpiredCode from '../../../../Components/ExpiredCode'
import useHover from '../../../../Utilities/useHover'
import useMediaQuery from '../../../../Utilities/useMediaQuery'
import useOutsideClick from '../../../../Utilities/useOutsideClick'

import { LanguageContext } from '../../../../Contexts/Language'
import { getTrendBookViewer, useAuthState, useAuthDispatch } from '../../../../Contexts/MainContext'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'
import '@react-pdf-viewer/thumbnail/lib/styles/index.css'

function TrendBookViewer(props) {

	const { userLanguage, dictionary } = useContext(LanguageContext)

	const dispatch = useAuthDispatch()
	const { token } = useAuthState()

	const pageNavigationPluginInstance = pageNavigationPlugin()
	const { GoToNextPage, GoToPreviousPage, jumpToPage } = pageNavigationPluginInstance
	const thumbnailPluginInstance = thumbnailPlugin()
	const { Thumbnails } = thumbnailPluginInstance
	const customAnnotationPluginInstance = customAnnotationPlugin()

	const [currentPageNum, setCurrentPageNum] = useState()
	const [currentThemeContent, setCurrentThemeContent] = useState()
	const [currentThemeID, setCurrentThemeID] = useState()
	const [trendBook, setTrendBook] = useState()
	
	const [hideBackButton, setHideBackButton] = useState(false)
	const [showAdditionalContent, setShowAdditionalContent] = useState(false)
	const [showDownloads, setShowDownloads] = useState(false)
	const [showThemeList, setShowThemeList] = useState(false)
	const [showThumbnails, setShowThumbnails] = useState(false)
	const [showOrientationChange, setShowOrientationChange] = useState(true)
	const [animateAdditionalContentIcon, setAnimateAdditionalContentIcon] = useState(false)

	const [hoverLeftNav, leftNavHovered] = useHover()
	const [width, height] = useMediaQuery()

	const clickOutsideThemes = useRef()
	const clickOutsideThumbnailsDesktop = useRef()
	const additionalContentIcon = useRef()

	const handleGetTrendBookViewer = useCallback(async () => {
		try {
			let response = await getTrendBookViewer(dispatch, token, userLanguage, props.match.params.id)
			if (!response) return
			if (response.code === 'OK') {
				setTrendBook(response.data.book)
			}
		} catch (error) {
			console.log(error)
		}
	}, [dispatch, token, userLanguage, props.match.params.id])

	const handlePageChange = (e) => {
		console.log(e.currentPage)
		setCurrentPageNum(e.currentPage + 1)
	}

	const handleShowThumbnailsDesktop = () => {
		setShowThumbnails(true)
	}

	const handleToggleThumbnailsMobile = () => {
		if (width < 991 && height < 575) {
			setShowThumbnails(!showThumbnails)
		}
	}

	const handleSelectChange = (value) => {
		jumpToPage(value - 1)
	}

	const handleCloseDownloads = () => {
		setShowDownloads(false)
	}

	const handleCloseAdditionalContent = () => {
		setShowAdditionalContent(false)
	}

	useOutsideClick(clickOutsideThemes, () => {
		if (showThemeList) {
			setShowThemeList(false)
		}
	})

	useOutsideClick(clickOutsideThumbnailsDesktop, () => {
		if (showThumbnails) {
			setShowThumbnails(false)
		}
	})

	useEffect(() => {
		document.body.style.backgroundColor = '#000' // avoid white stripes on iPhone 11+
		return () => document.body.removeAttribute('style')
	}, [])

	useEffect(() => {
    handleGetTrendBookViewer()
	}, [handleGetTrendBookViewer])

	useEffect(() => {
		if (trendBook && currentPageNum) {
			if (trendBook.themes.length) {
				const findTheme = trendBook.themes.find(x => currentPageNum >= x.start && currentPageNum <= x.end)
				if (findTheme) {
					setCurrentThemeID(findTheme.id)
					setCurrentThemeContent(findTheme)
					if (currentPageNum === findTheme.start) {
						setShowAdditionalContent(true)
						setTimeout(() => {
							setShowAdditionalContent(false)
							setAnimateAdditionalContentIcon(true)
							setTimeout(() => {
								setAnimateAdditionalContentIcon(false)
							}, 5000)
						}, 4000)
					} else {
						setShowAdditionalContent(false)
					}
				} else {
					setCurrentThemeID()
					setCurrentThemeContent()
				}
			}
		}
	}, [trendBook, currentPageNum])

	useEffect(() => {
		setTimeout(() => {
			setHideBackButton(true)
		}, 5000)
	})

	useEffect(() => {
		if (width < 768) {
			if (height > width) {
				setShowOrientationChange(true)
			} else {
				setShowOrientationChange(false)
			}
		} else {
			setShowOrientationChange(false)
		}
	}, [width, height])

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<Worker workerUrl="/pdfjs-dist/pdf.worker.min.js">
				{
					trendBook ? (
						<>
							{
								showOrientationChange ? (
									<div className="orientation-change">
										<div className="orientation-change--icon-container">
											<div className="orientation-change--phone"><i className="orientation-change--icon"></i></div>
											<p>
												{dictionary.trendBooks.orientationChange1}<br/>
												{dictionary.trendBooks.orientationChange2}
											</p>
										</div>
									</div>
								) : (
									<div className="pdf-viewer">
										<ExpiredCode />
										<div className={classNames("pdf-viewer--left-nav", {'hovered': leftNavHovered})} ref={hoverLeftNav}>
											<button
												type="button"
												className={classNames("arrow-btn arrow-bwd secondary pdf-viewer--btn-back", {'hide': hideBackButton})}
												onClick={() => props.history.goBack()}
											>
												{ dictionary.buttons.back }
											</button>
											{
												currentThemeContent ? (
													currentThemeContent.contents.length ? (
														<button
															type="button"
															className="btn-unstyled pdf-viewer--btn pdf-viewer--btn-additional"
															onClick={() => setShowAdditionalContent(true)}
														>
															<span className={classNames('pdf-viewer--btn-additional--icon', {'animate': animateAdditionalContentIcon})} ref={additionalContentIcon}></span>
															{ dictionary.trendBooks.additional }&nbsp;<span className="pdf-viewer--btn-additional--count">({ currentThemeContent.contents.length })</span>
														</button>
													) : null
												) : null
											}
										</div>
										{
											currentThemeContent ? (
												currentThemeContent.contents.length ? (
													<>
														<div className={classNames('pdf-viewer--additional-content', {'show': showAdditionalContent})}>
															<AdditionalContent data={currentThemeContent.contents} onClose={handleCloseAdditionalContent} />
														</div>
													</>
												) : null
											) : null
										}
										<div
											className={classNames("pdf-viewer--page", {'thumbnails-shown': showThumbnails})}
											onClick={handleToggleThumbnailsMobile}
										>
											<Viewer
												fileUrl={trendBook.pdf}
												defaultScale={SpecialZoomLevel.PageFit}
												onPageChange={handlePageChange}
												plugins={[
													customAnnotationPluginInstance,
													pageNavigationPluginInstance,
													thumbnailPluginInstance
												]}
											/>
											<div
												className="pdf-viewer--thumbnail-hover-zone"
												onMouseOver={handleShowThumbnailsDesktop}
											>
												<span className="sr-only">Pages</span>
											</div>
											{
												showThumbnails && (
													<div className="pdf-viewer--thumbnails" ref={clickOutsideThumbnailsDesktop}>
														<Thumbnails />
													</div>
												)
											}
										</div>
										<div className="pdf-viewer--right-nav">
											{
												currentThemeContent ? (
													<>
														<button
															type="button"
															disabled={!currentThemeContent.colors.length && !currentThemeContent.harmonies.length && !currentThemeContent.images.length && !currentThemeContent.sketches.length}
															className="btn-unstyled pdf-viewer--btn pdf-viewer--btn-downloads"
															onClick={() => setShowDownloads(true)}
															aria-label={ dictionary.trendBooks.downloads.title }
														></button>
														{
															currentThemeContent.colors.length || currentThemeContent.harmonies.length || currentThemeContent.images.length || currentThemeContent.sketches.length ? (
																<div className={classNames('pdf-viewer--downloads', { 'show': showDownloads })}>
																	<Downloads onClose={handleCloseDownloads} onThemeChange={handleSelectChange} book={trendBook} currentTheme={currentThemeID} currentContent={currentThemeContent} />
																</div>
															) : null
														}
													</>
												) : null
											}
											<div className="pdf-viewer--page-navigation">
												<GoToNextPage>
													{
														(props) => (
															<button
																type="button"
																className="btn-unstyled pdf-viewer--btn pdf-viewer--btn-next tap-expand-before"
																disabled={props.isDisabled}
																onClick={props.onClick}
															><span className="sr-only">{ dictionary.buttons.next }</span></button>
														)
													}
												</GoToNextPage>
												<GoToPreviousPage>
													{
														(props) => (
															<button
																type="button"
																className="btn-unstyled pdf-viewer--btn pdf-viewer--btn-prev tap-expand-before"
																disabled={props.isDisabled}
																onClick={props.onClick}
															><span className="sr-only">{ dictionary.buttons.previous }</span></button>
														)
													}
												</GoToPreviousPage>
											</div>
											{
												trendBook.themes.length ? (
													<>
														<button
															type="button"
															className="btn-unstyled pdf-viewer--btn pdf-viewer--btn-themes"
															onClick={() => setShowThemeList(!showThemeList)}
															aria-label={ dictionary.trendBooks.themes }
														></button>
														<div className={classNames("pdf-viewer--theme-list", { 'show': showThemeList })} ref={clickOutsideThemes}>
															<div className="pdf-viewer--theme-list--inner">
																<div className="pdf-viewer--theme-list--title">{ dictionary.trendBooks.themes }</div>
																{
																	Object.keys(trendBook.themes).map((key, i) => (
																		<button
																			key={i}
																			type="button"
																			className={classNames('btn-unstyled pdf-viewer--theme-list--btn', {
																				'active': trendBook.themes[key].id === currentThemeID }
																			)}
																			onClick={() => jumpToPage(trendBook.themes[key].start - 1)}
																		>
																			{trendBook.themes[key].title}
																		</button>
																	))
																}
															</div>
														</div>
													</>
												) : null
											}
										</div>
									</div>
								)
							}
						</>
					) : null
				}
			</Worker>
		</>
	)
}

export default TrendBookViewer
